@charset "utf-8";
/* ================================================================================
値の定義
================================================================================ */
// フォントサイズ
$mainRemFontSize: 62.5%; // =10pxに相当
// 基本フォントサイズ(SP)
$mainFontSpSize: 100%;
// 基本横幅
$basicWidth: 1020px;
// 基本カラー
$basicColor: #000;
$basicWhiteColor: #fff;
$keyColor: #c00;
$borderColor: #999;
$thinBorderColor: #ccc;
$bgColor: #f0f0f0;
$mainColor: #98c043;
// ブレークポイント定義
$breakpoints: ("md": "screen and (max-width : 960px)",
  "tab": "screen and (min-width : 768px) and (max-width : 1024px)",
  "pc": "screen and (min-width : 768px)",
  "fixed": "screen and (min-width : 1366px)",
) !default;
$properties: ('pt': 'padding-top',
  'pl': 'padding-left',
  'pb': 'padding-bottom',
  'pr': 'padding-right',
  'mt': 'margin-top',
  'ml': 'margin-left',
  'mb': 'margin-bottom',
  'mr': 'margin-right',
  'wd': 'width',
  'he': 'height',
  'top': 'top',
  'left': 'left',
  'bottom': 'bottom',
  'right': 'right',
) !default;
// ブレークポイント処理
// 使用例：@include mq(md) {}
@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
/* ====================================================
importファイル
======================================================= */
//ライブラリ
@import "lib/swiper.min.scss";
/* ==============================================
TOPページ
============================================== */
#main {
  .about {
    * {
      box-sizing: border-box;
    }
    padding-bottom: 150px;
    .about_box {
      align-items: flex-end;
      @include mq(md) {
        padding-top: 100px;
      }
      .box-col {
        width: 100%;
        max-width: 445px;
        padding-bottom: 100px;
        .inner_contents {
          padding: 0;
          .catch_text {
            font-size: 2.6rem;
            letter-spacing: -1px;
            @include mq(md) {
              font-size: 2.2rem;
              letter-spacing: inherit;
            }
          }
        }
      }
      .box-col2 {
        flex: 1;
        padding-left: 50px;
        @include mq(md) {
          padding-left: 0;
        }
        .photos {
          display: flex;
          img {
            max-width: 100%;
          }
          .column {
            @include mq(md) {}
            &:first-child {
              width: 100%;
              max-width: 340px;
              @include mq(md) {
                max-width: none;
                width: 50%;
              }
              .conceptImg {
                &:last-child {
                  padding-top: 30px;
                  width: 44.12%;
                  margin-left: auto;
                  @include mq(md) {
                    width: 74.12%;
                    padding-top: 16px;
                  }
                }
              }
            }
            &:last-child {
              flex: 1;
              padding-left: 33px;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              @include mq(md) {
                width: 50%;
                flex: auto;
                padding-left: 18px;
              }
              .conceptImg {
                &:first-child {
                  width: 59.52%;
                  padding-bottom: 30px;
                  @include mq(md) {
                    width: 79.52%;
                    padding-bottom: 16px;
                  }
                }
                &:last-child {
                  width: 99.21%;
                }
              }
            }
          }
        }
      }
    }
  }
  .rental_space {
    padding: 0 0 45px;
    @include mq(md) {
      padding-bottom: 0;
    }
    * {
      box-sizing: border-box;
    }
    .box-col {
      width: 59%;
      display: flex;
      align-items: center;
      @include mq(md) {
        width: 100%;
      }
      .concept_flex_box {
        display: block;
        img {
          max-width: 100%;
        }
        .box1 {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          align-items: flex-end;
          padding-bottom: 30px;
          margin-bottom: 0;
          @include mq(md) {
            padding-bottom: 16px;
            justify-content: space-between;
          }
          img {
            &:first-child {
              width: 68.18%;
              @include mq(md) {
                width: 67.9%;
              }
            }
            &:last-child {
              width: 27.27%;
              margin-left: 30px;
              object-fit: cover;
              height: 230px;
              @include mq(md) {
                width: 26.9%;
                height: 100px;
                margin-left: 0;
              }
            }
          }
        }
        .box2 {
          display: flex;
          width: 100%;
          justify-content: space-between;
          img {
            &:nth-child(1) {
              width: 36.36%;
            }
            width: 27.27%;
          }
        }
      }
    }
    .box-col2 {
      flex: 1;
      .inner_contents {
        padding-left: 40px;
        @include mq(md) {
          padding-left: 0;
        }
      }
      .catch_text {
        font-size: 2.6rem;
        @include mq(md) {
          font-size: 2.4rem;
        }
      }
    }
  }
}